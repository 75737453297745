import { Component, ElementRef, Inject, OnInit, ViewChild, Renderer2 } from "@angular/core";
import { Subscription } from "rxjs";
import { environment } from "src/environments/environment";
import { ActivatedRoute, ActivationStart, Router } from "@angular/router";
import { ContribuinteService } from "../../core/api/contribuinte.service";
import { SharedService } from "../api/shared.service";
import { AvisoService } from "src/app/core/api/aviso.service";
import { TipoExibicaooEnumAll, TipoExibicao } from "src/app/core/models/tipo-exibicao.enum";
import { DialogAvisoComponent } from "src/app/core/processo/dialog-aviso/dialog-aviso.component";
import { MatDialog, MatPaginator } from "@angular/material";
import { MessageService } from "../message/message.service";
import { AppConfig, APP_CONFIG } from "src/app.config";
import { Cidadao } from "src/app/core/models/seguranca/cidadao.model";
import { domain } from "process";
import { Usuario } from "src/app/core/models/usuario.model";
import { MeuPerfilService } from "src/app/core/api/meu-perfil.service";
import { state } from "@angular/animations";
import { DocumentoService } from "src/app/core/api/documento.service";
import { OcorrenciaService } from "src/app/core/api/ocorrencia.service";
@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"],
})
export class HomeComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild("senha") menuRef: ElementRef;

  public subscription: Subscription;
  public messages: Array<any> = [];
  public usuario: any;
  public visao = environment.VISAO;
  public documento: string;
  public ambiente: string;
  public corAmbiente: string;
  public screenWidth: number;
  public avisoCount: number = 0;
  public tipoExibicao: number;
  public isPossuiPerfilAnalistaOuAdministrador: boolean;
  public isPossuiPerfilAcessoMenuCadastroSegurança: boolean;
  public isPossuiPermissaoMaster: boolean;
  public isPossuiPerfilParaAbrirProcesso: boolean;
  public routeSub: Subscription;
  public title: string;
  public perfil: any;
  public lotacao: any;

  contexto: any;
  endpoint: any;
  itemMenu: boolean = false;

  constructor(
    public router: Router,
    private contribuinteSevice: ContribuinteService,
    private avisoService: AvisoService,
    private messageService: MessageService,
    public dialog: MatDialog,
    public sharedService: SharedService,
    private route: ActivatedRoute,
    private renderer: Renderer2,
    private menuService: MeuPerfilService,
    public documentoService: DocumentoService,
    public ocorrenciaService: OcorrenciaService,
    @Inject(APP_CONFIG) public config: AppConfig
  ) {
    this.screenWidth = window.innerWidth;
    window.onresize = () => {
      this.screenWidth = window.innerWidth;
    };
    this.title = route.snapshot.data.title;
  }

  ngOnDestroy() {
    this.routeSub.unsubscribe();
  }

  ngAfterViewInit() {
    // const menuElement = this.menuRef.nativeElement;
    // this.renderer.listen(menuElement, "click", (event: Event) => {
    //   const target = event.target as HTMLElement;
    //   if (target.tagName === "A") {
    //     const menuItemText = target.innerText;
    //     this.menuItemClick(menuItemText);
    //   }
    // });
  }

  menu = [
    {
      label: "Prateleira de processos",
      path: "/sgeap/lista-processo-tabela",
      icon: "folder_copy",
      isVisible: () => this.isPossuiPerfilAnalistaOuAdministrador === true,
    },
    {
      path: "/sgeap/lista-meus-processos",
      icon: "contact_page",
      label: "Minhas Solicitações",
    },
    {
      label: "Minhas Pendências",
      path: "/sgeap/pendencias",
      icon: "pending_actions",
      isVisible: () => this.visao == 2,
      infoHTML: () => `
      <b>Situação Inicial:</b> ${this.usuario} 
      <br/>
    `,
    },
    {
      path: "/sgeap/consultar-processo",
      icon: "search",
      isVisible: () => this.isPossuiPerfilAnalistaOuAdministrador === true,
      label: "Consultar Processos",
    },
    {
      path: "/sgeap",
      icon: "home",
      label: "Menu de Serviços",
      isVisible: () => this.isPossuiPerfilParaAbrirProcesso === true,
    },
    {
      path: "/sgeap/cadastro-home",
      icon: "library_add",
      label: "Cadastros",
      isVisible: () => this.isPossuiPerfilAcessoMenuCadastroSegurança === true,
    },
    {
      path: "/sgeap/seguranca-home",
      icon: "security",
      label: "Segurança",
      isVisible: () => this.isPossuiPerfilAcessoMenuCadastroSegurança === true,
    },
    {
      path: "/sgeap/modulo-assinador",
      icon: "edit_document",
      label: "Modulo Assinador",
    },
  ];

  ngOnInit() {
    this.routeSub = this.router.events.pipe().subscribe((e) => {
      if (e instanceof ActivationStart) this.title = e.snapshot.data.title;
    });

    this.isPossuiPerfilAnalistaOuAdministrador = this.sharedService.verificaRolePessoaLogada("frontend.menu-prateleira");

    this.isPossuiPerfilAcessoMenuCadastroSegurança = this.sharedService.verificaRolePessoaLogada(
      "frontend.menu-cadastro",
      "frontend.menu-seguranca"
    );

    this.isPossuiPerfilParaAbrirProcesso = this.sharedService.verificaRolePessoaLogada("frontend.menu-servico");

    //Rota inicial de acordo com o Perfil
    if (this.isPossuiPerfilAnalistaOuAdministrador === false) {
      this.router.navigate([environment.CONTEXTO, "lista-meus-processos"]);
      this.title = "Minhas Solicitações";
      // http://localhost:4200/sgeap/salvar-processo-pensao?idTipoPensao=3
    } else {
      // http://localhost:4200/sgeap/salvar-processo-pensao?idTipoPensao=3
      this.router.navigate([environment.CONTEXTO, "lista-processo-tabela"]);
      this.title = "Prateleira de processos";
    }

    if (this.sharedService.getDocumentoAstra()) {
      this.documento = JSON.parse(this.sharedService.getDocumentoAstra());
      this.tipoExibicao = TipoExibicaooEnumAll.filter((te) => te.descricao == TipoExibicao.PUBLICO_INTERNO).map((te) => te.id)[0];
    } else {
      this.tipoExibicao = TipoExibicaooEnumAll.filter((te) => te.descricao == TipoExibicao.PUBLICO_EXTERNO).map((te) => te.id)[0];
      this.documento = JSON.parse(this.sharedService.getDocumentoRepresentado());
      if (this.documento === null || this.documento === undefined)
        this.documento = JSON.parse(this.sharedService.getDocumentoRepresentante());
    }
    this.avisoService.countAvisoAtualPorTipoExibicao(this.tipoExibicao).subscribe(
      (res) => {
        this.avisoCount = res;
      },
      (error) => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );

    this.contribuinteSevice.findByDocumento(this.documento).subscribe(
      (usuario) => {
        if (usuario) {
          this.usuario = usuario;
          this.usuario.nome = this.sharedService.decodePayloadJWT().name;
          this.usuario.documento = this.sharedService.decodePayloadJWT().sub;
          this.documento = this.sharedService.decodePayloadJWT().sub;
          this.perfil = this.sharedService.decodePayloadJWT().perfil;
          this.lotacao = this.sharedService.decodePayloadJWT().orgaoSigla;
        } else {
          this.contribuinteSevice.findByDocumento(this.documento).subscribe((usuarioContribuinte) => {
            this.usuario = usuarioContribuinte;
            this.usuario.nome = this.sharedService.decodePayloadJWT().name;
            this.usuario.documento = this.sharedService.decodePayloadJWT().sub;
            this.documento = this.sharedService.decodePayloadJWT().sub;
            this.perfil = this.sharedService.decodePayloadJWT().perfil;
            this.lotacao = this.sharedService.decodePayloadJWT().orgaoSigla;
          });
        }
      },
      (error) => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );

    this.ambienteAplicacao();

    this.tipoExibicao = TipoExibicaooEnumAll.filter((te) => te.descricao == TipoExibicao.PUBLICO_INTERNO).map((te) => te.id)[0];

    this.listarDocumentosPendentesPorProcessos();
    this.listarOcorrenciaPorDocumentoSolicitante();
  }

  sair() {
    this.sharedService.logout();
  }

  menuItemClick(itemText: string) {
    this.menuService.menuItemClick(itemText);
  }

  // coloca rota para um modal contendo Nome (disabled), CPF (disabled), campo de Nova Senha e Confirmação.
  public async edit(usuario: Usuario, itemText: string): Promise<void> {
    if (itemText === "Meu Perfil") {
      this.itemMenu = true;
    }

    this.menuItemClick(itemText);
    await this.router.navigate([`salvar-cidadao/editar/${usuario.id}`], { queryParams: { itemMenu: this.itemMenu } });
  }

  ambienteAplicacao() {
    if (this.config.PRODUCAO) {
      this.ambiente = null;
      this.corAmbiente = null;
    } else if (this.config.HOMOLOGACAO) {
      this.ambiente = "HOMOLOGAÇÃO";
      this.corAmbiente = "red";
    } else {
      this.ambiente = "DESENVOLVIMENTO";
      this.corAmbiente = "orange";
    }
  }

  isLogado() {
    // tslint:disable-next-line: triple-equals
    return !(this.sharedService.getToken() == undefined || this.sharedService.getToken() == null || this.sharedService.getToken() == "");
  }

  private listarDocumentosPendentesPorProcessos() {
    this.documentoService.listarDocumentosPendenteAssinatura(this.documento).subscribe((documentos) => {
      this.avisoCount += documentos.length;
    })
}

  private listarOcorrenciaPorDocumentoSolicitante() {
    this.ocorrenciaService.listarOcorrenciaPorDocumentoSolicitante(this.documento).subscribe((ocorrencias) => {
      this.avisoCount += ocorrencias.length;
    })
  }

  openAvisoDialog() {
    this.avisoService.listarAvisoAtualPorTipoExibicao(this.tipoExibicao).subscribe(
      (res) => {
        if (res.length > 0) {
          const dialogRef = this.dialog.open(DialogAvisoComponent, {
            maxWidth: "600px",
            data: res,
          });
        }
      },
      (error) => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );
  }
}
